@import "../../_globalColor";

.audio-header-title {
  font-size: 56px;
  font-weight: 400;
  line-height: 0px;
}

.audio {
  width: 75%;
  height: 500px;
  //max-width: 610px;
}

.audio-main-div {
  //display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(315px, 1fr)); //used for listing individual tracks.
  gap: 1rem 1rem;
}
.subTitle {
  color: $subTitle;
}

@media (max-width: 768px) {
  .audio-header-title {
    font-size: 30px;
    text-align: center;
  }
  .audio-header-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .audio {
    width: auto;
  }
  .audio-main-div {
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    width: auto;
    padding: 1.5rem;
  }
}
